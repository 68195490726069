.home-slider {
    margin-top: 0;
  
    @media (min-width: 768px) {
      margin-top: -3rem;
    }
}
  
.carousel-caption {
  bottom: 5%;

  @media (min-width: 850px) {
    bottom: 30%;
  }

  h1 {
    font-weight: 100;
    font-size: 1.3rem;

    @media (min-width: 600px) {
      font-size: 3rem;
    }

    span {
      font-weight: 500;
    }
  }

  p {
    color: #fafafa;
    font-size: .8rem;
    text-align: center;

    @media (min-width: 600px) {
      font-size: 1.2rem;
    }
  }

  h6 {
    position: relative;
    font-size: .8rem;

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 8px;
      width: 50px;
      height: 2px;
      background-color: red;
    }
    
    &::before {
      margin-left: -3.8rem;
    }
  }
}
  