@import './styles/topbar';
@import './styles/nav';
@import './styles/slide';
@import './styles/animations';
@import './styles/main';
@import './styles/about';
@import './styles/health';
@import './styles/footer';

.right_border {
  border-right: 0;

  @media (min-width: 768px) {
    border-right: 1px solid #eaeaea !important;
  }
}

.bottom_border {
  border-right: 0;

  @media (min-width: 768px) {
    border-bottom: 1px solid #eaeaea;
  }
}

.extra_space {
  height: 20rem;
}

.animate_feature {
  &, a, svg {
    transition: all 0.5s ease-in-out;
  }

  a {
    opacity: 0;
  }

  svg {
    opacity: 1;
  }

  &:hover {
    transform: translateY(-40px);

    a {
      opacity: 1;
    }

    svg {
      opacity: 0;
    }
  }
}

.page-header {
  width: 100%;
  background: url('./images/slide_silo.jpg') no-repeat center center;
  background-size: cover;
  height: 20rem;
  padding-top: 6rem;
  margin-bottom: 5rem;

  h4 {
    text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);

  }
}

.custom-switch {
  padding-left: 0;
}

.about-img {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  transition: all 0.5s ease-in-out;

  &:hover {
    transform: translateY(-10px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
}

.underline_hover {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #c3002f;
    transform: scale(0, 1);
    // transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  &:hover::after {
    transform: scale(1, 1);
  }
}