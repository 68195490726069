* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  text-decoration: none;
  list-style: none;
}

body {
  margin: 0;
  font-family: 'Rubik', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  line-height: 1.4;
  color: #555c63 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.kfm_red {
  color: #c3002f;
}

h2 {
  font-family: "Rubik",Arial,Helvetica,sans-serif;
  font-weight: 500;
  font-size: 50px;
  line-height: 64px;
  color: #222d35;
}

.main-head {
  font-size: 1.35rem;
  line-height: 1.5rem;
}

.sub-head {
  font-size: 1.2rem;
  font-weight: 500;
}

.primary-head {
  font-size: 2.5rem;
  font-weight: 500;
}